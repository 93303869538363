import React from "react";

import Layout from "../../../components/Layout/Layout";

const Article01092020 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">„TEKWILL IN EVERY SCHOOL”! </h1>
            <p>
              The transformation of education can only take place through joint
              efforts.
            </p>

            <p>
              Both Google and S&T Mold believe in the power of education in
              Moldova and joined the project "Tekwill in every school" in the
              crowdfunding campaign. It is an important contribution, thanks to
              which thousands of children and teachers will be able to study
              quality educational courses and will become better prepared for
              the future occupation. Success comes only when we are united!
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article01092020;
